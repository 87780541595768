@import "./variables";

.GDPR {
    @include formBasicBox;

    &__form {
        @include formBasicContainer;

        &__title {
            @include formHeader;
        }

        &__text {
            padding: 0 $unit;
            color: $grey-medium;
            font-size: 18px;

            a {
                color: $grey-medium;
            }
        }

        &__input {
            width: 100%;
            padding: 0 $unit;

            label {
                display: flex;
                align-items: center;

                & > ::before {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    position: relative;
                    left: -80px;
                    top: 7px;
                    background-color: #d4e2f1;
                    box-shadow: inset 0px 0px 2px 0px rgba(39, 23, 52, 0.25);
                }

                input:checked + span::before {
                    background-image: url("../images/checkmark.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            span {
                font-size: 18px;
                text-align: left;
            }

            a {
                color: #271734;
            }

            input {
                -webkit-appearance: none;
                padding: 15px;
                margin-right: $unit;
                opacity: 0;
            }
        }

        &__action {
            margin-top: $unit * 2;
            color: $white-primary;
            font-size: 18px;
            border-radius: 4px;
            border: none;
            background-color: $orange;
            padding: $unit / 2 $unit;
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: lighten($orange, 10%);
            }

            &:disabled {
                background-color: $grey-medium;
            }
        }

        &__exit {
            @include exitButton;
            margin-left: 240px;
        }
    }
}

@media (max-width: 500px) {
    .GDPR {
        height: 100%;
        margin-top: 0;

        &__form {
            &__input {
                padding: 0 $unit * 2;
            }

            &__exit {
                margin-left: 37%;
            }
        }
    }
}
