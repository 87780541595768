@import "../_variables.css";

.ModalSessionEnded {
    position: absolute;
    background-color: var(--author-color-gray);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ModalSessionEnded div {
    width: 100%;
    text-align: center;
}

.ModalSessionEnded h1 {
    color: var(--author-color-black-medium);
    font-family: var(--author-font-family);
    font-size: calc(var(--author-font-size) * 2);
}

.ModalSessionEnded button {
    padding: var(--author-unit);
    background-color: var(--author-color-orange);
    color: var(--author-color-white);
    border: none;
    border-radius: var(--author-border-radius);
    margin-right: calc(var(--author-unit) / 2);
    font-size: var(--author-font-size);
    cursor: pointer;
}

.ModalSessionEnded button:nth-child(2) {
    background-color: var(--author-color-black-medium);
}
