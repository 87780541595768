@import "./styles/variables";
@import "./styles/fonts";
@import "./styles/vendor";

* {
    box-sizing: border-box;
    outline: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $blue-light;
}

#root {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
}

//TODO: Can we solve this in the shared components project?

.e360-app__container {
    width: 38%;
    overflow: scroll;
}
