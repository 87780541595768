@import "../_variables.css";

.author-AboveTargetLevelPopup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.author-AboveTargetLevelPopup h4:first-child {
    margin-top: 0;
}

.author-AboveTargetLevelPopup__replacement-suggestions {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.author-AboveTargetLevelPopup__replacement-suggestions-column > * {
    white-space: pre;
    cursor: pointer;
    margin-top: 0.4em;
}

.author-AboveTargetLevelPopup__replacement-suggestions-column > :hover {
    color: var(--author-color-orange);
}

.author-AboveTargetLevelPopup__replacement-suggestions-column :first-child {
    margin-top: 0;
}

.author-AboveTargetLevelPopup__replacement-suggestions-column:nth-child(2) {
    margin-left: 0.4em;
}
