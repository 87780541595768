@import "./variables";

.ContactSupport {
    @include formBasicBox;

    &__container {
        @include formBasicContainer;

        &__title {
            justify-content: space-between;
            @include formHeader;

            h1 {
                font-size: 24px;
            }

            &-close {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__subtitle {
            padding: 0 $unit;
            color: $grey-medium;
            font-size: 18px;

            a {
                color: $blue-dark;
                text-decoration: none;
            }
        }

        &__block {
            border: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: $unit;

            &__label {
                @include formLabel;
            }

            &__input {
                @include formInput;
            }
        }

        &.confirm {
            padding: 48px;
            height: 677px;
            justify-content: center;

            p:first-child {
                font-size: 48px;
                width: 100%;
                margin: 48px 0 16px 0;
            }
            p:nth-child(2) {
                margin-bottom: 64px;
            }
        }
    }

    &__action {
        @include formButton;

        &:hover {
            background-color: lighten($orange, 10%);
        }

        &:disabled {
            background-color: $grey-medium;
        }
    }

    &__exit {
        @include exitButton;
    }
}

.message {
    height: 200px;
    overflow: scroll;
    padding: $unit;
}

@media (max-width: 600px) {
    .ContactSupport {
        @include formBasicBoxMobile;
        &__container {
            &__title {
                border-radius: 0;
            }
        }
    }
}
