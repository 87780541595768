@import "../_variables.css";

.author-HighlightPopup {
    --padding: var(--author-unit);

    background: white;
    font-family: var(--author-font-family);
    border-radius: var(--author-border-radius);
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 3px 1px;
    min-width: 350px;
    max-width: 550px;
    overflow: hidden;
}

.author-HighlightPopup__topbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding);
    background: var(--author-color-background);
    color: var(--author-color-white);
    font-size: 36px;
    height: 64px;
    overflow: hidden;
    box-sizing: border-box;
}

.author-HighlightPopup__title {
    font-size: 18px;
}

.author-HighlightPopup__content {
    display: flex;
    padding: var(--padding) calc(2 * var(--padding));
    background: var(--author-color-white);
    min-height: 128px;
    box-sizing: border-box;
}

.author-HighlightPopup .Loader {
    height: 16px;
    align-self: center;
    margin: auto;
}

.author-HighlightPopup .Loader .Loader__circle {
    background-color: var(--author-color-blue-light);
}
