.author-MarkerPicker {
    display: flex;
    width: 100%;
    margin: 16px 0 0;
}

.author-MarkerPicker div {
    max-width: calc(var(--author-unit) * 7);
}

.author-MarkerPicker div:not(:last-child) {
    margin-right: calc(var(--author-unit) * 3);
}

.author-MarkerPicker div:first-child button {
    width: calc(var(--author-unit) * 4);
}

.author-MarkerPicker button {
    padding: calc(var(--author-unit) / 2) calc(var(--author-unit) / 4);
    text-transform: uppercase;
    background-color: var(--author-color-white);
    border-radius: var(--author-border-radius);
    color: var(--author-color-black-medium);
    border: 1px solid var(--author-color-black-medium);
    font-size: var(--author-font-size-small);
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.author-MarkerPicker button:active,
.author-MarkerPicker button:hover,
.author-MarkerPicker button:focus,
.author-MarkerPicker .active {
    color: var(--author-color-white);
    background: var(--author-color-black-medium);
}

.author-MarkerPicker label {
    font-size: var(--author-font-size-small);
    display: flex;
    align-items: center;
    font-size: var(--author-font-size-small);
    font-weight: 300;
    color: var(--author-color-blue-medium);
    line-height: var(--author-line-height-small);
    width: 100%;
    text-align: center;
    height: calc(var(--author-unit) * 2);
    /* extra padding necessary in Author frontend */
    padding: var(--author-unit) 0;
}

.author-MarkerPicker div button:disabled {
    color: var(--author-color-white);
    background-color: var(--author-color-gray-dark);
    border-color: var(--author-color-gray-dark);
    cursor: default;
}

@media (max-width: 1280px) {
    .author-MarkerPicker label {
        font-size: 0.6em;
    }
}

@media (max-width: 1120px) {
    .author-MarkerPicker div:not(:last-child) {
        margin-right: calc(var(--author-unit));
    }
}
