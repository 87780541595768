/*
Use BEM! 

- Avoid style overriding on the original app

*/

@import "./_variables.css";
@import "./_vendor.css";
@import "./_utils.css";

.e360-app__container {
    background-color: var(--author-color-white);
    padding: calc(var(--author-unit) * 2);
    padding-bottom: 0;
    max-width: calc(var(--author-unit) * 34);
    overflow: scroll;
    -webkit-overflow-scrolling: touch; /* smooth scrolling iOS */
    background: var(--author-color-background);
    transform: translate3d(0, 0, 0); /* safari iOS z-index fix */
}

.e360-app__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.author-AnalysisResult__action {
    outline: none;
    font-size: 13px;
    font-weight: normal;
    color: var(--author-color-orange);
    background-color: transparent;
    text-transform: uppercase;
    padding: 5px 14px;
    border-color: var(--author-color-orange);
    border-radius: var(--author-border-radius);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* TODO: Safari seems to have issues with calc */
    min-height: 33px; /* calc(var(--author-unit * 2)); */
    min-width: 192px; /* calc(var(--author-unit * 12)); */
}

.author-AnalysisResult__action.warning {
    border: 1px solid var(--author-color-orange);
    background-color: var(--author-color-orange);
    color: var(--author-color-white);
}

.author-AnalysisResult__action__image {
    height: 21px;
    margin-right: 5px;
}
