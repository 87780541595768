@import "./variables";

section div.Testimonials__container {
    display: flex;
    margin: $unit 0;
    width: 100%;
}

section.Testimonials {
    margin: 0;
}

.Testimonials {
    flex-direction: column;

    h1 {
        padding-left: $unit;
        font-size: 45px;
        font-family: $font-family-home;
        margin: 0;

        strong {
            color: #007ac2;
        }
    }

    &__block {
        width: 33%;
        padding: $unit;
        font-size: 16px;

        &__quote {
            color: $grey-dark;
            line-height: 24px;
            font-style: italic;
        }

        &__name {
            color: $grey-medium;
            font-weight: bold;

            .blue {
                color: $blue-dark;
            }
        }
    }
}

@media (max-width: 1024px) {
    .Testimonials {
        h1 {
            margin-top: $unit;
        }

        &__block {
            width: 100%;
            font-size: 16px;
        }
    }
    section div.Testimonials__container {
        flex-direction: column;
        width: 100%;
    }
}

@media (max-width: 760px) {
    .Testimonials {
        h1 {
            font-size: 24px;
            margin-top: $unit;
        }

        &__block {
            width: 100%;
        }
    }
    section div.Testimonials__container {
        flex-direction: column;
        width: 100%;
    }
}
