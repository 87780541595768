$unit: 16px;

$font-family: Roboto, sans-serif;
$font-family-home: "Barlow Condensed", sans-serif;
$font-size: 14px;
$font-size-medium: 16px;
$font-size-small: 11px;

// --- color

// Greys and Whites
$white-primary: #fff;
$black-primary: #000;

$grey-dark: #4a4a4a;
$grey-medium: #9b9b9b;
$grey-light: #d8d8d8;
$grey-border: #5f697a;
$grey-background: #e7e7e9;

// Blues and purples
$blue-dark: #007ac2;
$blue-medium: #6590b9;
$blue-light: #d4e2ee;
$blue-link: #0078c1;

$purple: #271734;
// colors
$color-purple: #271734;
$color-orange: #ff601d;

//Oranges and Yellow
$orange: #ff601d;

// Shadows
$shadow-black: rgba(0, 0, 0, 0.5);

//Mixins

@mixin formButton {
    min-width: 250px;
    margin-top: $unit;
    color: $white-primary;
    font-size: 24px;
    border-radius: 4px;
    border: none;
    background-color: $orange;
    padding: $unit;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.5s;
}

@mixin exitButton {
    margin-top: $unit;
    font-size: 18px;
    color: $white-primary;
    background: $grey-medium;
    border: none;
    border-radius: 4px;
    width: 125px;
    padding: $unit / 4;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

@mixin formBasicBox {
    max-width: 600px;
    width: 100%;
    margin: auto;
    background: $white-primary;
    padding-bottom: $unit * 2;
    margin-top: 100px;
    border-radius: 4px;
    font-size: 18px;
    box-shadow: inset 0px 0px 5px 0px rgba(39, 23, 52, 0.25);
    box-shadow: 0px 0px 30px 0px rgba(39, 23, 52, 0.25);
}

@mixin formBasicBoxMobile {
    height: 100%;
    margin-top: 0;
    border-radius: 0;
}

@mixin formBasicContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@mixin formHeader {
    display: flex;
    align-items: center;
    margin: 0;
    background: $purple;
    color: $white-primary;
    padding: $unit;
    border-radius: 4px 4px 0 0;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    height: 80px;
}

@mixin formLabel {
    color: $grey-medium;
    font-family: $font-family;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    margin-bottom: $unit;
}

@mixin formInput {
    border: 1px solid $grey-medium;
    border-radius: 4px;
    height: 60px;
    width: 100%;
    padding: 0 $unit;
    font-size: 18px;
    resize: none;
}
