@import "./variables";

$animation-duration: 15s;
$animation-delay: 1s;

@keyframes highlight-hard {
    0% {
        background-color: #ffaaaa;
    }
    60% {
        background-color: #ffaaaa;
    }
    100% {
        background-color: #ffaaaa00;
    }
}

@keyframes clickAnimation {
    0% {
        box-shadow: 1px 1px 10px #222;
        transform: scale(1);
    }
    3% {
        box-shadow: none;
        transform: scale(0.9);
    }
    5% {
        box-shadow: 1px 1px 10px #222;
        transform: scale(1.05);
    }
    6% {
        box-shadow: 1px 1px 10px #222;
        transform: scale(1);
    }
    100% {
        box-shadow: 1px 1px 10px #222;
        transform: scale(1);
    }
}

@keyframes cefrScoreAnimation {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes mouseMoveAnimation {
    3% {
        transform: scale(0.9);
    }
    4% {
        transform: scale(1);
    }

    15% {
        transform: translateY(0);
    }

    35% {
        transform: translate(-200%, -125%);
    }

    70% {
        transform: translate(-510%, -150%);
    }

    90% {
        transform: translate(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes mouseMoveAnimationIpad {
    3% {
        transform: scale(0.9);
    }
    4% {
        transform: scale(1);
    }

    15% {
        transform: translateY(0);
    }

    35% {
        transform: translate(-190px, -80px);
    }

    70% {
        transform: translate(-295px, -80px);
    }

    90% {
        transform: translate(0);
    }

    100% {
        transform: translateY(0);
    }
}

.Mainpage section div.LandingImage1 {
    width: 500px;
}

.LandingImage1 {
    box-shadow: 0 0 50px -20px lighten($color-purple, 70%);
    border: 1px solid lighten($color-purple, 70%);
    border-radius: 4px;

    header {
        width: 100%;
        display: block;

        ul {
            text-align: left;
            padding: $unit 0 0 $unit;
            margin: 0;

            // colors used for li: https://developer.apple.com/design/human-interface-guidelines/macos/visual-design/color/
            li {
                list-style-type: none;
                width: $unit;
                height: $unit;
                background-color: rgb(255, 204, 0);
                display: inline-block;

                margin-right: $unit / 2;
                border-radius: 50%;
            }

            :first-child {
                background-color: rgb(255, 59, 48);
            }

            :last-child {
                background-color: rgb(40, 205, 65);
            }
        }
    }
    article {
        display: flex;
        height: 250px;

        p {
            text-align: left;
            width: 70%;
            margin: $unit;
        }
    }

    aside {
        width: 30%;
        background-color: $color-purple;
        padding: 8px;
        border-bottom-right-radius: 4px;
        text-align: center;

        button {
            width: 80%;
            margin-top: $unit;
            padding: $unit / 2;
            border-radius: 4px;
            color: $white-primary;
            background-color: $color-orange;
            border: 1px solid $color-orange;
            font-size: $font-size-medium;
            box-shadow: 0 0 15px darken($color-purple, 50%);
            animation-duration: $animation-duration;
            animation-name: clickAnimation;
            animation-iteration-count: infinite;
        }

        .mouse-pointer {
            width: $unit * 4;
            height: $unit * 4;
            position: relative;
            top: -$unit * 1.5;
            left: $unit * 1.5;

            animation-duration: $animation-duration;
            animation-iteration-count: infinite;
            animation-name: mouseMoveAnimation;
        }
    }
}

.hard {
    background-color: transparent;
    animation-delay: $animation-delay;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-name: highlight-hard;
}

.cefr-score {
    font-family: "Helvetica", sans-serif;
    color: white;
    font-size: 32px;
    text-align: center;
    width: 100%;
    margin: $unit 0;
    opacity: 0;
    animation-delay: $animation-delay;
    animation-duration: $animation-duration;
    animation-name: cefrScoreAnimation;
    animation-iteration-count: infinite;

    label {
        font-size: 0.25em;
        display: block;
    }
}

@media (max-width: 1366px) {
    .LandingImage1 {
        margin-left: $unit * 2;
    }
}

@media (max-width: 1024px) {
    .LandingImage1 {
        margin: 0;
    }

    .Mainpage section div.LandingImage1 {
        width: 450px;
    }
}

@media (max-width: 425px) {
    .Mainpage section div.LandingImage1 {
        display: none;
    }
}
