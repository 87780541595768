@import "../_variables.css";

.author-AnalysisResult {
    margin-top: 32px;
    font-family: var(--author-font-family);
    width: 100%;
}

.author-AnalysisResult__analysis {
    overflow: hidden;
    background: var(--author-color-white);
}
