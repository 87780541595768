@import "../_variables.css";

.Loader {
    margin: 0;
    padding: 0;
    display: flex;
}

.Loader__circle {
    list-style: none;
    width: 8px;
    height: 8px;
    background-color: var(--author-color-white);
    margin: 0 8px;
    border-radius: 50%;
    animation: bounce 0.8s linear infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

ul li:nth-child(1) {
    animation-delay: 0.35s;
}
ul li:nth-child(2) {
    animation-delay: 0.05s;
}
ul li:nth-child(3) {
    animation-delay: 0.68s;
}
