@import "./variables";

.HomePage .PageHeader {
    &__container {
        width: 25%;
    }
}

.PageHeader {
    height: $unit * 4;
    width: 100%;
    background: $white-primary;
    border-bottom: 1px solid $purple;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $unit * 2;
    z-index: 0;

    &__logo {
        padding: $unit / 2;
        height: 43px;
        margin-top: 5px;

        @media (max-width: 500px) {
            & {
                height: 50px;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 38%;
        max-width: calc(var(--author-unit) * 32);
        padding-left: 32px;

        &__button {
            border: none;
            background-color: $orange;
            min-width: 100px;
            height: 33px;
            font-size: 12px;
            text-align: center;
            border-radius: 4px;
            padding: $unit / 2;
            color: $white-primary;
            text-decoration: none;
            cursor: pointer;
            margin: 0 10px;
            transition: background-color 0.5s;

            &:hover {
                background-color: lighten($orange, 10%);
            }
        }
    }

    &__no-connection {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        height: $unit * 1.5;
        width: 100%;
        background-color: $orange;
        color: $white-primary;
        font-size: $font-size-small;
    }

    &__login,
    &__loggedIn {
        justify-content: flex-end;
    }
}

@media (max-width: 425px) {
    .PageHeader {
        padding: 0 $unit / 2;
    }
}
