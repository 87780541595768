@import "./variables";
@import "./LandingPageAnimation.scss";

.Mainpage {
    width: 100%;

    background-color: $white-primary;
    padding: $unit * 4;
    padding-top: $unit * 2;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &__container {
        max-width: 1024px;
        margin: 0 auto;
    }

    &__animationImage {
        display: none;
    }

    section.mobile {
        display: none;
    }

    section.powerful {
        height: 500px;
        margin-bottom: 0;
    }

    section {
        display: flex;
        margin: $unit * 3 0;

        & > div {
            width: 50%;
            display: inline-block;
        }
    }

    &__info:first-child {
        padding-right: $unit * 2;
    }

    &__info {
        display: inline-block;

        strong {
            color: $blue-dark;
        }

        h1 {
            font-size: 45px;
            font-family: $font-family-home;
            margin-top: 0;
        }

        p {
            font-size: 24px;
        }

        &-special {
            width: 200%;
            max-width: 800px;
        }
    }

    &__clock {
        margin: 0 auto;
    }

    &__powerful {
        position: relative;
        left: -140px;

        img {
            width: 170%;
            top: -150px;
            position: relative;
            left: -100px;
        }
    }

    &__tagline {
        margin: $unit * 6 auto;
        margin-top: 0;
        font-size: 48px;
        text-align: center;
        width: 100%;
    }

    &__technical {
        margin: $unit * 6 auto;
        text-align: left;
        font-size: $font-size * 1.5;
        color: $grey-medium;

        a {
            color: $grey-medium;
            text-decoration: none;
            border: 1px solid;
            padding: $unit;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background-color: $color-orange;
                color: $white-primary;
                border-color: $color-orange;
            }
        }

        & > div {
            vertical-align: top;
            text-decoration: none;
        }

        div:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $unit * 2;
        }

        div:last-child a {
            border: none;
        }
    }

    footer {
        border-top: 1px solid $grey-light;
        width: 100%;

        div {
            display: flex;
            justify-content: space-between;

            ul {
                width: 1fr;
                text-align: left;
                list-style-type: none;
                padding: 0;

                li {
                    padding-bottom: $unit / 2;
                    a {
                        color: $grey-medium;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &__copyright {
        color: $grey-light;
        font-family: $font-family-home;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        width: 25%;
        min-width: 210px;
        border-top: 1px solid $grey-light;
        padding-top: $unit / 2;
        margin: $unit auto $unit * 2;
    }
}

@media (max-width: 1366px) {
    .Mainpage {
        &__powerful {
            img {
                top: -100px;
            }
        }
    }
}

@media (max-width: 1104px) {
    .Mainpage {
        &__powerful {
            img {
                width: 561px;
                top: -135px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .Mainpage {
        section.reverse {
            flex-direction: column-reverse;
        }

        section.powerful {
            height: 700px;
        }

        section {
            flex-direction: column;
            margin: $unit * 1.5 0;

            & > div {
                width: 100%;
            }
        }

        &__info {
            &-special {
                width: 100%;
            }
        }

        &__powerful {
            left: 0;

            img {
                width: 120%;
                left: 0;
            }
        }

        &__tagline {
            margin-top: 0;
            margin-bottom: $unit * 2;
            font-size: 2em;
        }
    }
}

@media (max-width: 760px) {
    .Mainpage {
        width: 100%;
        padding: $unit;

        h1 {
            font-size: 24px;
        }

        p {
            font-size: 18px;
        }

        &__powerful {
            height: 300px;
        }
    }
}

@media (max-width: 425px) {
    .Mainpage {
        &__animationImage {
            display: block;
        }
        section.reverse {
            margin-top: 95px;
            margin-bottom: -5px;

            img {
                height: 120px;
            }
        }
        section.powerful {
            height: 250px;
        }
        &__powerful {
            top: -35px;
            left: 50px;

            img {
                top: -160px;
                width: 100%;
            }
        }

        &__tagline {
            display: none;
        }

        &__technical {
            font-size: 18px;

            a {
                color: $white-primary;
                background-color: $blue-dark;
            }
        }
        section.mobile {
            display: block;
            h1 {
                font-family: $font-family-home;
            }

            span.mobile__success {
                font-size: 24px;
                color: $blue-dark;
            }

            form {
                height: 180px;
                background-color: $purple;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                padding: $unit;
                margin-top: $unit * 2;
                margin-bottom: $unit * 4;

                fieldset {
                    width: 100%;
                    border: none;
                    background-color: $white-primary;
                    font-size: 18px;
                    height: 100px;
                    padding: $unit;

                    label {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;

                        input {
                            font-size: 18px;
                            height: $unit * 2;
                        }
                    }
                }

                button {
                    height: $unit * 2;
                    max-width: 140px;
                    font-size: 18px;
                    margin-top: $unit;
                    color: $white-primary;
                    border-radius: 4px;
                    border: none;
                    background-color: $orange;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .Mainpage {
        &__powerful {
            img {
                top: -120px;
            }
        }
    }
}
