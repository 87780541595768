@import "./variables";

.CefrPaper {
    background: $white-primary;
    width: 75%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    text-align: right;
    font-size: 24px;

    h2 {
        font-size: 48px;
        font-family: $font-family-home;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: $unit / 2;
    }

    &__close {
        padding: $unit;
    }

    &__container {
        max-width: 1024px;
        margin: 0 auto;
        text-align: left;

        > section {
            padding: $unit * 2;
        }
    }

    &__header {
        h1 {
            color: $blue-dark;
            font-family: $font-family-home;
            font-size: 48px;
            font-weight: 300;
            margin: 0;
        }
    }

    &__human {
        background: $blue-light;
        h2 {
            margin-top: 0;
        }

        > div {
            width: 100%;
            display: flex;
        }

        &__container {
            display: flex;
            flex-direction: column;
            width: 50%;
            &__image {
                background-color: $white-primary;
                padding: $unit;
            }
            img {
                width: 100%;
            }
        }

        span {
            margin: $unit;
        }
    }

    &__training {
        div {
            display: flex;
            p {
                padding-right: $unit;
            }
        }
    }

    &__algorithm {
        display: flex;
        background: $blue-dark;

        &__container {
            background: white;
            width: 65%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                padding: 16px;
            }
        }

        &__block {
            color: white;
            width: 30%;
            padding: 0 $unit;
            margin: 0 auto;
        }
    }

    &__accuracy {
        background: $blue-light;

        > div {
            margin: $unit * 2 0;
        }

        ul {
            padding: 0;
            list-style-type: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
            }
        }

        &__check {
            height: 60px;
            width: 60px;
        }

        &__chart {
            display: flex;

            img {
                width: 65%;
                margin-right: $unit * 2;
            }
            &__titles {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                span:first-child {
                    margin-top: $unit;
                }
                span:last-child {
                    margin-bottom: $unit * 3;
                }
            }
        }
    }

    &__more {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            font-family: $font-family-home;
            border: none;
            color: $blue-dark;
            padding: $unit $unit * 2;
            border-radius: 4px;
            font-size: 32px;

            a {
                display: none;
            }
        }
        div {
            width: 80%;
            display: flex;
            list-style-type: none;
            padding: 0;
            justify-content: space-around;
            margin-top: $unit * 3;

            a {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                color: $black-primary;

                img {
                    margin-bottom: $unit;
                }
            }
        }
    }

    &__footnotes {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $grey-medium;

        > div {
            width: 45%;
            color: $grey-medium;
        }

        div:first-child {
            font-size: 18px;
            font-family: $font-family-home;

            span {
                text-transform: uppercase;
            }
        }

        div:last-child {
            font-size: 14px;

            span {
                display: flex;
            }

            p {
                margin-top: 0;
                margin-left: $unit / 2;
            }
        }

        a {
            color: $grey-medium;
        }
    }

    footer {
        padding: $unit $unit * 2;
        margin-bottom: $unit * 3;
        color: $grey-medium;

        & > div {
            width: 100%;
            display: flex;
            justify-content: space-between;

            span {
                font-family: $font-family-home;
                text-transform: uppercase;
            }

            p {
                color: $grey-light;
                font-size: 18px;
            }
        }

        img {
            height: 60px;
        }

        & > div.copyright {
            justify-content: center;
        }
    }
}

@media (max-width: 1024px) {
    .CefrPaper {
        &__human {
            &__text {
                span {
                    font-size: 0.75em;
                }
            }
        }

        &__algorithm {
            font-size: 20px;
            flex-direction: column;
            align-items: center;
            img {
                padding: 0;
            }

            &__block {
                padding: 0;
                width: 100%;
            }
        }

        &__accuracy {
            ul {
                li {
                    font-size: 0.8em;
                }
            }

            &__check {
                height: 40px;
                width: 40px;
            }

            &__text {
                transform: translateY(-$unit * 2);
            }

            &__chart {
                position: relative;
                img {
                    width: 100%;
                    margin-right: 0;
                    position: relative;
                    top: $unit;
                }
                &__titles {
                    font-size: 0.75em;
                    margin: 0;

                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -$unit * 1.5;
                    bottom: -$unit * 1.5;
                    text-align: center;
                    color: $grey-medium;

                    span {
                        margin: 0;
                    }

                    span:first-child {
                        margin: 0;
                    }
                    span:last-child {
                        margin: 0;
                    }
                }
            }
        }

        &__more {
            div {
                a {
                    font-size: 0.75em;
                }
            }
        }

        footer {
            .edia-tag-line {
                font-size: 0.75em;
            }
        }
    }
}

@media (max-width: 425px) {
    .CefrPaper {
        width: 100%;
        font-size: 18px;
        h1,
        h2 {
            font-size: 32px;
        }

        &__container {
            > section {
                padding: $unit;
            }
        }

        &__human {
            > div {
                flex-direction: column;
            }

            &__container {
                width: 100%;
            }
        }

        &__algorithm {
            font-size: 18px;
            h3 {
                font-size: 32px;
            }
            &__container {
                width: 100%;
            }
        }

        &__accuracy {
            &__text {
                ul {
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 32px;
                    font-weight: 300;

                    img {
                        margin-right: $unit * 2;
                    }
                }
            }
        }

        &__more {
            span {
                font-size: 24px;
                padding: $unit 0;
                text-align: center;

                span {
                    display: none;
                }
                a {
                    display: block;
                    color: $blue-dark;
                }
            }

            div {
                margin-top: 16px;
                a img {
                    height: 40px;
                }
            }
        }

        &__footnotes {
            flex-direction: column;
            width: 100%;

            div {
                width: 100%;
            }
        }

        footer {
            padding: $unit;
            img {
                height: 45px;
            }

            > div.copyright {
                justify-content: flex-start;
            }
        }
    }
}
