@import "./variables";

.PasswordEnterEmail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__block {
        @include formBasicBox;

        &__container {
            @include formBasicContainer;

            &__title {
                @include formHeader;
            }

            fieldset {
                border: none;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: $unit;

                label {
                    @include formLabel;
                }

                input {
                    @include formInput;
                }
            }

            &__action {
                @include formButton;

                &:hover {
                    background-color: lighten($orange, 10%);
                }

                &:disabled {
                    background-color: $grey-medium;
                }
            }

            &__exit {
                @include exitButton;
            }
        }
    }
}
