.author-BlockHeader__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--author-color-blue-medium);
    color: var(--author-color-white);
    font-size: var(--author-font-size-large);
    font-weight: 300;
    line-height: var(--author-line-height);
    text-transform: uppercase;
    height: 64px;
    padding: 0 15px;
    cursor: pointer;
}

.author-BlockHeader__header__level {
    font-size: 30px;
    font-weight: 500;
}

@media (max-width: 1817px) {
    .author-Readability .author-BlockHeader__header,
    .author-Readability .author-BlockHeader__header__level {
        font-size: 12px;
    }
}

.author-BlockHeader__header__toggle {
    cursor: pointer;
}
