@import "../_variables.css";

.author-Header header {
    color: var(--author-color-blue-medium);
    font-family: var(--author-font-family);
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    margin-bottom: 32px;
}
