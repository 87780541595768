@import "../_variables.css";

.author-CefrProbabilities .author-BlockSection__content {
    margin-top: 0;
}

.author-CefrProbabilities table {
    width: 100%;
    border-collapse: collapse;
}

.author-CefrProbabilities__table {
    overflow: hidden;
    margin-top: 50px;
}

.author-CefrProbabilities__name {
    width: 45px;
    text-align: center;
    height: 32px;
    border-top: 2px solid var(--author-color-black-medium);
    border-left: 1px solid var(--author-color-black-medium);
    border-right: 1px solid var(--author-color-black-medium);
    cursor: pointer;
}

.author-CefrProbabilities__name--selected {
    width: 45px;
    text-align: center;
    height: 32px;
    background: var(--author-color-black-medium);
    color: var(--author-color-white);
    border-top: 2px solid var(--author-color-black-medium);
    border-left: 1px solid var(--author-color-black-medium);
    border-right: 1px solid var(--author-color-black-medium);
    position: relative;
}

@media (max-width: 1024px) {
    .author-CefrProbabilities table td {
        font-size: 0.8em;
    }
}

.author-CefrProbabilities__probability {
    padding: 5px;
}

.tooltip__row {
    text-align: center;
}

.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    overflow: visible;
    position: relative;
}

.tooltip__text {
    font-size: 9px;
    font-weight: 300;
    color: var(--author-color-blue-medium);
    position: absolute;
    top: calc(var(--author-unit) / 6);
    transform: translateX(calc(var(--author-unit) * -0.2));
    min-width: calc(var(--author-unit) * 3);
}

.tooltip__position {
    display: block;
    background: var(--author-color-blue-medium);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    position: relative;
    transform: translateY(5px);
}
.tooltip__position::before {
    content: "";
    border-width: 14px 10px 0px 11px;
    border-style: solid;
    border-color: var(--author-color-blue-medium) transparent transparent
        transparent;
    top: var(--author-unit);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.name__cell {
    text-align: center;
}

.name__target {
    font-size: 9px;
    font-weight: 300;
    color: var(--author-color-blue-medium);
}
