@import "./variables";

.HomePage {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__container {
        display: flex;
        height: calc(100% - 64px); // height of toolbar
    }
}
