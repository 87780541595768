@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

/* generic */

:root {
    /* AuthorTool  fonts */
    --author-font-size-small: 10px;
    --author-font-size: 14px;
    --author-font-size-large: 18px;
    --author-unit: 16px;
    --author-font-family: Roboto, sans-serif;
    --author-line-height: 16px;
    --author-line-height-small: 11px;

    /* color */

    --author-color-black: #000;
    --author-color-black-medium: #222222;
    --author-color-black-medium-rgb: 34, 34, 34;
    --author-color-white: #fff;
    --author-color-gray-light: #efefef;
    --author-color-gray: #e7e7e9;
    --author-color-gray-dark: #979797;
    --author-color-blue: #0069a3;
    --author-color-blue-medium: #6590b9;
    --author-color-blue-light: #81b0de;
    --author-color-background: #271734;
    --author-color-yellow: #f5a623;
    --author-color-red: #e30613;
    --author-color-orange: #ff601d;

    /* borders, etc */
    --author-border-radius: 4px;
}
