@import "../_variables.css";

.author-Block {
    margin-top: 32px;
    font-family: var(--author-font-family);
    width: 100%;
}

.author-Block:last-of-type {
    margin-bottom: calc(var(--author-unit) * 2);
}

.author-Block__content {
    overflow: hidden;
    background: var(--author-color-white);
    position: relative;
}

.author-Block__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}
