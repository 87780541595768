@import "./variables";

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__container {
        display: flex;
        height: calc(100% - 64px); // height of toolbar
        background: lighten($grey-background, 7%);

        .author-TextEditor {
            max-width: 826px;
            margin: $unit * 2 auto;
            flex-grow: 1;

            @media (max-width: 1024px) {
                & {
                    margin: 0;
                }
            }
        }
    }
}
