.author-TextEditor {
    background: var(--author-color-white);
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.33);
}

.author-TextEditor__wrapper {
    display: flex;
    height: 100%;
    overflow: auto;
    position: relative;
}

.author-TextEditor .quill {
    /*min-height: 100%;*/
    display: flex;
    flex-direction: column;
}
.author-TextEditor .quill .ql-toolbar {
    position: sticky;
    top: 0;
    background: var(--author-color-white);
    z-index: 1;
}
.author-TextEditor .quill .ql-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.author-TextEditor .ql-editor {
    min-height: 100%;
    flex-grow: 1;
    /* padding: var(--author-unit) 6.6%; */
    padding: var(--author-unit) 2.2%;
}

.author-TextEditor--previewing
    .author-TextEditor__regular-text-editor
    .ql-editor,
.author-TextEditor--previewing
    .author-TextEditor__regular-text-editor
    .ql-editor
    * {
    /* hide text from regular text editor when showing preview so that text doesn't look bold*/
    color: transparent !important;
    /* ensure the caret is still visible */
    caret-color: var(--author-color-black);
}

.author-TextEditor__regular-text-editor,
.author-TextEditor__preview-text-editor {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.author-TextEditor .quill {
    min-height: 100%;
}

.author-TextEditor__preview-text-editor .author-TextEditor__poppers {
    --margin: 0.4em;

    position: absolute;
    top: 0;
    pointer-events: all;
}
.author-TextEditor__preview-text-editor
    .author-TextEditor__poppers
    > [x-placement="bottom"] {
    margin-top: var(--margin);
}

.author-TextEditor__preview-text-editor
    .author-TextEditor__poppers
    > [x-placement="top"] {
    margin-bottom: var(--margin);
}

.author-TextEditor__preview-text-editor,
.author-TextEditor__preview-text-editor .quill,
.author-TextEditor__preview-text-editor .quill * {
    pointer-events: none;
}

.author-TextEditor__preview-text-editor .quill .ql-editor > p > span,
.author-TextEditor__preview-text-editor .quill .ql-editor .author-highlight {
    pointer-events: all;
}
.author-TextEditor__preview-text-editor .quill .ql-editor .author-highlight {
    display: inline;
}

.author-TextEditor__preview-text-editor .ql-container {
    border-color: transparent;
}

.author-TextEditor .ql-container,
.author-TextEditor .ql-toolbar {
    border: none;
}
