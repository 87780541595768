@import "../_variables.css";

.author-LinguisticsBlock__section {
    font-family: var(--author-font-family);
}

.author-LinguisticsBlock__section table {
    width: 100%;
}

.author-LinguisticsBlock__container {
    height: calc(var(--author-unit) * 2);
}

.author-LinguisticsBlock__container td {
    width: 50%;
}

.author-LinguisticsBlock__container td:first-child {
    font-size: var(--author-font-size);
    text-transform: uppercase;
    font-weight: 300;
}
