@import "../_variables.css";

.author-KeywordBlock__filter__container {
    padding-top: var(--author-unit);
}

.author-KeywordBlock__filter {
    display: flex;
    width: 100%;
    height: 32px;
    margin: var(--author-unit) 0;
}

.author-KeywordBlock__filter__title {
    font-size: var(--author-font-size);
    font-weight: 300;
}

.author-KeywordBlock__filter__button {
    padding: calc(var(--author-unit) / 2) calc(var(--author-unit) / 4);
    text-transform: uppercase;
    background-color: var(--author-color-white);
    border-radius: var(--author-border-radius);
    color: var(--author-color-black-medium);
    border: 1px solid var(--author-color-black-medium);
    font-size: var(--author-font-size-small);
    text-align: center;
    cursor: pointer;
}

.author-KeywordBlock__filter__button:not(:last-child) {
    margin-right: calc(var(--author-unit) * 3);
}

.author-KeywordBlock__filter__button.active {
    color: var(--author-color-white);
    background: var(--author-color-black-medium);
}

.author-KeywordBlock__keywords {
    display: flex;
    width: 100%;
    margin: var(--author-unit) 0;
}

.author-KeywordBlock__keywords-column {
    font-size: var(--author-font-size);
    font-weight: 300;
}

.author-KeywordBlock__keywords-column:first-child {
    margin-right: calc(var(--author-unit) * 3);
}

.author-KeywordBlock__keyword {
    padding: calc(var(--author-unit) / 2) calc(var(--author-unit) / 4)
        calc(var(--author-unit) / 4);
}

.author-KeywordBlock__keyword.active span {
    border-bottom: 1px solid var(--author-color-orange);
    color: var(--author-color-orange);
}

.author-KeywordBlock__keyword span {
    cursor: pointer;
}
.author-KeywordBlock__keyword span:hover {
    color: var(--author-color-orange);
}
