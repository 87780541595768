@import "../_variables.css";

.author-BlockSection {
    padding: var(--author-unit);
    font-family: var(--author-font-family);
    font-size: var(--author-font-size);
    border-top: 1px solid var(--author-color-blue-medium);
}

.author-BlockSection__header {
    display: flex;
    justify-content: space-between;
    padding-left: var(--author-unit);
    align-items: center;
    position: relative;
}

.author-BlockSection__title {
    font-size: var(--author-font-size);
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
}

.author-BlockSection__content {
    overflow: hidden;
    padding: 0 var(--author-unit);
}

.author-ToolExplanation {
    position: absolute;
    width: 60%;
    right: calc(var(--author-unit) * 3);
    top: calc(var(--author-unit) / 2);
    background: var(--author-color-gray-dark);
    padding: 8px;
    border-radius: var(--author-border-radius);
    color: var(--author-color-white);
    text-align: center;
    box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3), 3px 6px 4px rgba(0, 0, 0, 0.2),
        5px 10px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;
}

.author-BlockSection.KeywordMarker
    .author-MarkerPicker
    button[name="KEYWORDS_FILTER"] {
    display: none;
}
