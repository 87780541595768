@import "../_variables.css";

.toggle {
    margin: var(--author-unit) 0;
    max-width: 100px;
    font-size: var(--author-font-size-small);
    padding: calc(var(--author-unit) / 2) calc(var(--author-unit) / 4);
    text-transform: uppercase;
    background-color: var(--author-color-white);
    border-radius: var(--author-border-radius);
    color: var(--author-color-black-medium);
    border: 1px solid var(--author-color-black-medium);
    text-align: center;
    cursor: pointer;
}

.author-ReaderComprehensionGraph .author-BlockSection__content {
    margin-top: 0;
}

.author-ReaderComprehensionGraph {
    font-family: var(--author-font-family);
    font-size: 14px;
    border-top: 1px solid var(--author-color-blue-medium);
}

.author-ReaderComprehensionGraph__header {
    display: flex;
    justify-content: space-between;
    padding-left: var(--author-unit);
}

.author-ReaderComprehensionGraph__title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
}

.author-ReaderComprehensionGraph__table {
    overflow: hidden;
    margin-top: calc(var(--author-unit) * 2);
}

.author-ReaderComprehensionGraph table {
    width: calc(100% - var(--author-unit));
    padding-top: var(--author-unit);
}

.author-ReaderComprehensionGraph__row td,
.author-ReaderComprehensionGraph__selected td {
    padding-bottom: var(--author-unit);
}

.author-ReaderComprehensionGraph__selected
    .author-ReaderComprehensionGraph__name
    span {
    color: var(--author-color-white);
    transform: translateX(calc(var(--author-unit) * 0.01));
    display: inline-block;
}

.author-ReaderComprehensionGraph__selected span:before {
    content: "";
    height: calc(var(--author-unit) * 2);
    width: calc(var(--author-unit) * 2);
    background-color: var(--author-color-black-medium);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    top: -8px;
    left: -6px;
}

.author-ReaderComprehensionGraph__selected
    .author-ReaderComprehensionGraph__bar {
    background: var(--author-color-black-medium);
}

.author-ReaderComprehensionGraph__selected
    .author-ReaderComprehensionGraph__percentage {
    color: var(--author-color-black-medium);
}

.author-ReaderComprehensionGraph__name {
    width: calc(var(--author-unit) * 2);
    font-weight: 500;
    padding: 0;
    height: var(--author-unit);
}

.author-ReaderComprehensionGraph__result {
    position: relative;
    padding: 0;
    padding-left: var(--author-unit);
    height: var(--author-unit);
    display: flex;
}

.author-ReaderComprehensionGraph__bar {
    background: var(--author-color-yellow);
    display: inline-block;
    height: var(--author-unit);
    animation: author-grow 400ms;
    transition: width 400ms;
    margin-right: var(--author-unit);
}

.author-ReaderComprehensionGraph__bar[style="width: 0%;"] {
    margin-right: 0;
}

.author-ReaderComprehensionGraph__percentage {
    color: var(--author-color-yellow);
    max-width: var(--author-unit);
    font-weight: 500;
}
