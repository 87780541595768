@import "../_variables.css";

.score-item {
    cursor: default;
    margin: var(--author-unit) * 2 0;
}

.score-level {
    color: var(--author-color-blue-medium);
}

.score-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--author-unit);
}

.bar-container {
    width: 100%;
    overflow: hidden;
    margin: 0 8px;
}

.score-number {
    cursor: default;
    animation: author-fadeIn-2-1 2400ms;
    color: var(--author-color-white);
    background-color: var(--author-color-yellow);
    padding: 5px 2px 1px 2px;
    border-radius: 50%;
    width: 22px;
    height: 20px;
    display: block;
    text-align: center;
    position: relative;
    font-size: var(--author-font-size-small);
    line-height: var(--author-line-height);
    box-sizing: content-box;
}

.score-number::before {
    content: "";
    border-width: 14px 11px 0px 11px;
    border-style: solid;
    border-color: var(--author-color-yellow) transparent transparent transparent;
    margin-top: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.score-bar {
    background-color: var(--author-color-gray-dark);
    height: 3px;
}

.score-bar-fill {
    background-color: var(--author-color-yellow);
    height: 3px;
    width: 0;
    margin-top: 10px;
    animation: author-grow 1000ms;
    height: 3px;
    margin-top: 10px;
    transition: width 400ms;
}
