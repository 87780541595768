@import "./variables";

.FeedbackForm {
    @include formBasicBox;

    &__container {
        @include formBasicContainer;

        &__title {
            justify-content: space-between;
            @include formHeader;

            h1 {
                font-size: 24px;
            }

            &-close {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__subtitle {
            padding: 0 $unit;
            color: $grey-medium;
            font-size: 18px;
        }

        &__block {
            border: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: $unit;

            &__label {
                @include formLabel;
            }

            &__input {
                @include formInput;
            }
        }

        &.send {
            img {
                width: 120px;
                height: 70px;
                margin: $unit * 3;
            }

            a {
                width: 350px;
                text-align: center;
            }
        }
    }

    &__action {
        @include formButton;

        &:hover {
            background-color: lighten($orange, 10%);
        }

        &:disabled {
            background-color: $grey-medium;
        }
    }

    &__exit {
        @include exitButton;
    }
}

.message {
    height: 200px;
    overflow: scroll;
    padding: $unit;
}

@media (max-width: 600px) {
    .FeedbackForm {
        @include formBasicBoxMobile;
        &__container {
            &__title {
                border-radius: 0;
            }
        }
    }
}
