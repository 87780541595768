@import "../_variables.css";

/* TODO: avoid the use of BEM (less useful here) */
.author-CefrSettings {
    width: 100%;
    border: 0;
    margin: 0;

    padding: 0;
}

.author-CefrSettings__title {
    color: var(--author-color-white);
    text-transform: uppercase;
    display: block;
    font-family: var(--author-font-family);
    font-weight: 300;
    font-size: var(--author-font-size);
    display: flex;
    justify-content: space-between;
    line-height: var(--author-unit);
}

.author-CefrSettings__title button {
    color: var(--author-color-white);
    background-color: var(--author-color-orange);
    border: 0;
    border-radius: var(--author-border-radius);
    text-transform: uppercase;
    font-size: var(--author-font-size-small);
}

.author-CefrSettings ul {
    padding: 0;
    margin: var(--author-unit) 0;
    width: 100%;
    display: flex;
}
.author-CefrSettings ul li {
    color: var(--author-color-white);
    list-style-type: none;
    padding: calc(var(--author-unit) / 2);
    border: 1px solid var(--author-color-white);
    cursor: pointer;
    font-family: var(--author-font-family);
    font-size: var(--author-font-size-small);
    border-left-width: 0px;
    width: 100%;
    text-align: center;
}

.author-CefrSettings ul li:first-child {
    border-left-width: 1px;
}

.author-CefrSettings .selected {
    background-color: var(--author-color-white);
    color: var(--author-color-black-medium);
    cursor: default;
}
